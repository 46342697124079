import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Testimonial from '../components/testimonials'
import '../css/lessons.css'

const LessonsPage = () => {
    return (
        <Layout pageTitle="Lessons" subTitle="Shamisen Lessons" description="Learn how to play shamisen. Pricing and information about shamisen classes with Makoto Nishimura in Tokyo.">
            <div className='lessons-page sub-wrapper'>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Introduction
                    </h2>
                    <div className='section-content'>
                        <p>
                            Lessons are by appointment, and should be scheduled at least one week in advance.
                        </p>
                        <p>
                            Makoto teaches primarily because she enjoys introducing people to traditional music, not in order to make money. Because of this, her tuition fees are <strong>EXTREMELY</strong> low. The lesson fees go toward the maintenance of the instruments, which is quite expensive; periodic replacement of strings; the cost of photocopying notation; and to help cover the expenses involved in organizing student concerts.
                        </p>
                        <p>
                            Lessons are taught in either English or Japanese.
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Lesson Fees
                    </h2>
                    <div className='section-content'>
                        <p>
                            Lesson Fees include the loan of a shamisen and its maintenance fee.
                        </p>
                        <p>
                            Since shamisen is the base and "conductor" of a nagauta ensemble, it is important for students to understand shamisen rhythms and the singing melodies before taking on other instruments such as drums or flute. If a student has interest or talent in other areas, she can also teach those instruments. But shamisen lessons are the first step into nagauta. The various lesson options are:
                        </p>
                    </div>
                    <table className='fee-table'>
                        <thead>
                            <tr>
                                <th>
                                    Lesson Length
                                </th>
                                <th>
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    60 mins.
                                </td>
                                <td>
                                    &yen;1500
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    90 mins.
                                </td>
                                <td>
                                    &yen;2000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    120 mins.
                                </td>
                                <td>
                                    &yen;2500
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='section-content'>
                        <p>
                            Lesson fees are paid at the end of each lesson. Most students take one lesson per week.<br />
                            Makoto is more concerned about the quality of the lesson, so if your lesson runs over by a few minutes, it's no problem!<br />
                            Lessons can be arranged for any day (including weekends) from 9am to 4pm.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-image'
                                alt='Makoto accompanies her student during a lesson.'
                                src='../images/lesson/lesson-1.jpg'
                            />
                        </div>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Do I need my own shamisen?
                    </h2>
                    <div className='section-content'>
                        <p>
                            Makoto can provide you with a shamisen for the lesson!<br />
                            For the first few months, until you are sure the shamisen is something you are truly interested in, you will not need to buy an instrument. Makoto has instruments at her house which you can play during your lessons. However, if you decide to continue (as with any other music study), you will probably need to consider purchasing an instrument in order to practice at home between lessons.
                        </p>
                        <p>
                            Buying a shamisen is similar to buying a violin; prices and quality range from low-cost unplayable junk to concert-grade instruments costing the equivalent of tens of thousands of dollars. When the time comes and you wish to purchase an instrument, Makoto will help you. She has a relationship with the <a href="https://e-kameya.com">shamisen makers Kameya</a> and they can provide a student-model shamisen of good quality at a reasonable price.
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        A typical shamisen lesson
                    </h2>
                    <div className='section-content'>
                        <p>
                            There is no exact pattern to shamisen lessons and Makoto adjusts both the lesson content and style to meet the needs and requests of her students. If a student has a request to learn to play a particular piece of music, that's completely fine. Or if they want to practice previous pieces that's fine too. Because it can be hard to find space to practice at home (Japanese apartment walls are notoriously thin), practising during the lesson is completely fine too.
                        </p>
                        <p>
                            For completely new students however, Makoto often finds it useful to begin with an explanation of shamisen musical notation as well as an explanation of shamisen tuning before trying to play. If you haven't played shamisen before this initial explanation makes it much easier to start playing and progress.
                        </p>
                        <p>
                            For both long term and new students Makoto highly recommends having some Japanese snacks to help the lesson along!
                        </p>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        The Classroom
                    </h2>
                    <div className='section-content'>
                        <p>
                            The shamisen lessons take place in Makoto's apartment, which is situaten near to Komazawa Olympic Park, in Meguro-ku, Tokyo. There are two stations about 15 minutes walk away, and bus stops nearby too.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-image'
                                alt='Makoto and her students play the taiko drums together.'
                                src='../images/lesson/lesson-4.jpg'
                            />
                        </div>
                    </div>
                </section>
                <section className='normal-section'>
                    <h2 className='section-header'>
                        Performances
                    </h2>
                    <div className='section-content'>
                        <p>
                            For long term students there will also be opportunities to give performances throughout the year. In order to inspire my nagauta students' training, I encourage everyone to perform. There may be small gigs throughout a year, but mainly I will prepare one large recital for all players once a year, held in a large hall in Tokyo, assisted by professional musicians.
                        </p>
                        <p>
                            Usually, student recitals are very costly in Japan. However, I am working hard to make such concerts affordable for my students. Students can expect to pay between &yen;20,000-30,000 for performance-related costs, such as rental of the hall, and fees to stage assistants and professional musicians.
                        </p>
                        <div className='static-img-container'>
                            <StaticImage
                                className='normal-flow-image'
                                alt='Students in kimono perform a shamisen recital'
                                src='../images/lesson/performance-2.webp'
                            />
                        </div>
                    </div>
                </section>
                <section className='normal-section '>
                    <h2 className='section-header'>Hear from previous students</h2>
                    <Testimonial />
                </section>
            </div>
        </Layout>
    )
}

export default LessonsPage