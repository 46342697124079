import * as React from 'react'

const Testimonial = () => {
    const testimonialArr = [
        ["Makoto has been an inspiration and a fantastic motivator in introducing the world of nagauta to us. Once totally unaware of this tradition, I now have a keen interest in a all things kabuki and no: going to the theater, listening to NHK radio at 11 am, as well as playing the music in the form of shamisen and tsuzumi. Thank you Makoto for allowing us the RARE opportunity to take part in something important in this fascinating country.", "--Carina Cameron, UK"],
        ["Former Prime Minister Mori made the call for more volunteers in Japan. Makoto Nishimura has set a wonderful example as a skilled nagauta teacher. Having the chance to learn various Japanese instruments without the hassle of hidden money and iemoto system has made living in Tokyo more bearable. Learning from Makoto continues to enrich my life here.", "--Aaron Comsia, USA"],
        ["Studying nagauta with Makoto this year has not only enabled me to experience a new kind of music, but has also given me insight into the life of a musical community that would otherwise have been closed to me. Thanks, Makoto, for giving me this opportunity!", "--Elizabeth Davidson, UK"],
        ["I remember the smell of autumn. Walking on a street in my quiet residential neighborhood, I remarked to my companion, \"You'd never know we were on the other side of the planet. All the world is the same.\" Then I heard...plink...plink...plink...coming from somewhere, drifting by on an evening breeze. It was the first time I'd ever heard a shamisen. Since then, a generous, patient, and talented teacher and friend has taught me how to make those very sounds, and I've never again forgotten that I'm in Japan. Thank you, Makoto.", "--Javier Fernandez, USA"],
        ["What grabbed me about Makoto's advertisement back in August 1999 were the words, \"Learn something about Japanese culture.\" That was exactly why I had decided to come to Japan, so my journey with nagauta began. The sounds, the slightly awkward way of holding the bachi, and the exquisite beauty of the shamisen itself, all intrigued me. The pleasure of riding the wave of beautifully scripted Japanese notes to the gentle sounds of tsuyu or high-pitched cicadas or yakiimoya san, only height the pleasure of learning nagauta with such a dedicated teacher as Makoto. Thank you, Makoto, for so much of your time and energy.", "--Caroline Flavin, Ireland"],
        ["What first impressed me about Makoto was her love of nagauta and enthusiasm for teaching shamisen. She is a full-time worker , who is undoubtedly tired by the time she returns home, and yet she finds the time to practice. Above and beyond her own learning, she generously teaches all of us . Why? Because she loves the music. From Makoto, I am learning not only to play shamisen, but to love the music as well.", "--Christine Foster, USA"],
        ["Makoto has been an inspirational teacher. She is kind, patient, and talented, some of the best qualities of an excellent teacher. Even though she has gone through the same beginner music pieces with me over and over, she is always excited about it. She has helped me develop my appreciation for Japanese music, especially the shamisen. Thank you, Makoto.", "--Angela Im, USA"],
        ["As with many things that have happened in my four and half years in Japan, my enjoyment of Japanese traditional music has taken me by surprise. I started shamisen lessons with Makoto in April, and I only wish I had started sooner ! Every lesson and workshop is an opportunity to learn and experience things I didn't even know existed.", "--Gretchen Jude, USA"],
        ["Before I met Makoto I had absolutely no idea what traditional Japanese music sounded like. Nor did I have any idea what the instruments used to play it looked like. On my first meeting it was overwhelming to sit with a shamisen awkwardly balanced on my lap, terrified that I was going to drop it. With a huge wooden plectrum fitting clumsily into my right hand, I desperately tried to follow Makoto's instructions and make some music instead of noise. Earlier on I had admitted, \"I know nothing about music.\" She just looked at me with a knowing smile and replied, \"Of course you don't. That why I invited you here...to learn about it.\" She seemed to have no doubt that was exactly what I would do.", "--Sally Langer, Australia"],
        ["My first picture of traditional Japanese music was that of a jungle, as of a bird's view, thus monochrome but full of secrets. Now, being guided on the path of nagauta, which reveals beauty and explains its construction, the jungle shows its paradise. There could be no other way to explore but with Makoto. Allerbesten Dank.", "--Jorg Maurer, Germany"],
        ["A sensei is more than a teacher. Without Makoto, I would have never discovered Japan's past and the beauty of its music. But her teaching has always extended beyond shamisen. Through her, I have learned: to surround yourself with \"nice spirit,\" to not waste time on things that have \"no meaning,\" to give generously and expect nothing in return, to find beauty in hidden places, to not try to change what you cannot, to support your dream life with a stable job, to maintain your dignity at all cost, and to always, have a plan. Thank you, Makoto, for your many rare gifts.", "--Janet Pocorobba, USA"],
    ]

    const returnArr = testimonialArr.map((testimony, index) => (
        <figure className='testimony-container' key={index}>
            <div className='section-content'>
                <blockquote className='testimony-quote'>
                    {testimony[0]}
                </blockquote>
                <figcaption className='testimony-name'>{testimony[1]}</figcaption>
            </div>
        </figure>
    ))

    return (
        <div className='testimony-section'>
            {returnArr}
        </div>
    )
}

export default Testimonial